import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  getCategoriesAPI,
  addCategoryAPI,
  updateCategoryAPI,
  deleteCategoryAPI,
  updateCategoryOrderAPI,
} from "../utils/ApiRequest";
import { AuthContext } from "./AuthContext";

export const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    if (!user) return;
    try {
      const { data } = await axios.get(`${getCategoriesAPI}/${user._id}`);
      const sortedCategories = data.categories.map((cat, index) => ({
        ...cat,
        sortOrder: cat.sortOrder !== undefined ? cat.sortOrder : index + 1,
      })).sort((a, b) => a.sortOrder - b.sortOrder);
      setCategories(sortedCategories);
    } catch (err) {
      console.error("Failed to fetch categories:", err.message);
    }
  };

  const createCategory = async (name) => {
    if (!user) return;
    try {
      const { data } = await axios.post(addCategoryAPI, {
        name,
        userId: user._id,
      });
      setCategories((prev) => [...prev, data.category]);
    } catch (err) {
      console.error("Error adding category:", err.message);
    }
  };

  const updateCategory = async (catId, newName) => {
    try {
      const { data } = await axios.put(`${updateCategoryAPI}/${catId}`, {
        name: newName,
      });
      setCategories((prev) =>
        prev.map((cat) => (cat._id === catId ? data.category : cat))
      );
    } catch (err) {
      console.error("Error updating category:", err.message);
    }
  };

  const removeCategory = async (catId) => {
    try {
      await axios.delete(`${deleteCategoryAPI}/${catId}`);
      setCategories((prev) => prev.filter((cat) => cat._id !== catId));
    } catch (err) {
      console.error("Error deleting category:", err.message);
    }
  };

  const updateCategoryOrder = async (newCategories) => {
    try {
      const payload = newCategories.map((cat, index) => ({
        id: cat._id,
        sortOrder: index + 1,
      }));
      await axios.put(updateCategoryOrderAPI, { userId: user._id, categories: payload });
      setCategories(newCategories);
    } catch (err) {
      console.error("Error updating category order:", err.message);
    }
  };

  useEffect(() => {
    if (!user) {
        // user가 null이면 카테고리를 초기화
        setCategories([]);
        return;
    }
    fetchCategories();
    // eslint-disable-next-line
  }, [user]);

  return (
    <CategoryContext.Provider
      value={{
        categories,
        fetchCategories,
        createCategory,
        updateCategory,
        removeCategory,
        updateCategoryOrder,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
