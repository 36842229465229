// src/pages/blog/BlogDetail.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getPostById, deletePost } from '../../services/blogService';
import { AuthContext } from '../../contexts/AuthContext';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthorInfo from "../../components/AuthorInfo";
import BlogComments from './BlogComments';
import { Helmet } from 'react-helmet';
import "./blog.css";

const host = process.env.REACT_APP_API_HOST;

function BlogDetail() {
  const { user } = useContext(AuthContext);
  const { id, slug  } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, []);

  const fetchPost = async () => {
    try {
      const data = await getPostById(id, slug);
      if (data.success) {
        setPost(data.post);
      } else {
        toast.error(data.message || 'Failed to fetch post', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error fetching post.', toastOptions);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;
    try {
      const data = await deletePost(id, (user && user._id) || 'guest');
      if (data.success) {
        toast.success('Post deleted successfully.', toastOptions);
        navigate('/blog');
      } else {
        toast.error(data.message || 'Failed to delete post.', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error deleting post.', toastOptions);
    }
  };

  if (!post) return <div className="container text-light">Loading...</div>;

  return (
    <>
      <Helmet>
        {/* 페이지 제목 설정 */}
        <title>{post.title}</title>
        {/* 메타 설명 설정 (내용의 처음 150자 정도를 사용) */}
        <meta name="description" content={post.content.slice(0, 170)} />

        {/* Open Graph 태그: 소셜 미디어에서 링크 미리보기로 활용 */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.content.slice(0, 150)} />
        {post.fileUrl && <meta property="og:image" content={`${host}/${post.fileUrl}`} />}
      </Helmet>
      <div className="container my-4">
        <div className="card bg-dark text-light">
          <div className="card-body">
            <h2 className="card-title">{post.title}</h2>
            <AuthorInfo 
                  userName={post.userName} 
                  userAvatar={post.userAvatar} 
                  createdAt={post.createdAt} 
              />
            {post.fileUrl && (
              <div className='d-flex justify-content-center'>
              <img
                src={`${host}/${post.fileUrl}`}
                alt={post.title}
                className="img-fluid my-3"
                style={{ maxWidth: '300px', width: '100%', height: 'auto' }}
              />
              </div>
            )}
            <div
              className="card-text"
              dangerouslySetInnerHTML={{ __html: post.content }}
              style={{ borderTop: '1px solid #444', paddingTop: '10px' }}
            />

            <div className="mt-4 d-flex justify-content-between">
              <Link to="/blog" className="btn btn-secondary">
                List
              </Link>
              {user && post.userId === user._id && (
              <div>
                <Link to={`/blog/edit/${post._id}`} className="btn btn-info me-2">
                  Edit
                </Link>
                <button onClick={handleDelete} className="btn btn-danger">
                  Delete
                </button>
              </div>
              )}
              {!post.userId && (
                <button onClick={handleDelete} className="btn btn-danger">
                  Delete
                </button>
              )}
            </div>
            <div className='mt-3'>
              <a
                href="https://link.coupang.com/a/cfXxCT"
                target="_blank"
                rel="noopener noreferrer"
                referrerPolicy="unsafe-url"
              >
                <img
                  src="https://ads-partners.coupang.com/banners/841324?subId=&traceId=V0-301-5f9bd61900e673c0-I841324&w=320&h=100"
                  alt=""
                  style={{ maxWidth: '320px', width: '100%', height: 'auto' }}
                />
              </a>
            </div>
            <BlogComments 
              blogPostId={post._id}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default BlogDetail;
