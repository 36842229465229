import React from 'react';
import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { AuthProvider } from './contexts/AuthContext';
import { CategoryProvider } from "./contexts/CategoryContext";
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
// App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import MainLayout from "./components/MainLayout";

import Home from './Pages/Home/Home';
import SetAvatar from './Pages/Avatar/setAvatar';

import BlogList from './Pages/blog/BlogList';
import BlogCreate from './Pages/blog/BlogCreate';
import BlogDetail from './Pages/blog/BlogDetail';
import BlogEdit from './Pages/blog/BlogEdit';

const App = () => {
  return (
    
      <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <CategoryProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/setAvatar" element={<SetAvatar />} />

                <Route path="/blog" element={<BlogList />} />
                <Route path="/blog/create" element={<BlogCreate />} />
                <Route path="/blog/:id/:slug" element={<BlogDetail />} />
                <Route path="/blog/edit/:id" element={<BlogEdit />} />
              </Route>
            </Routes>
          </CategoryProvider>
        </AuthProvider>
      </BrowserRouter>
      </div>
  )
}

export default App