import React from "react";
import { useNavigate } from "react-router-dom";
import { FaHome, FaPlus, FaSyncAlt, FaDownload } from "react-icons/fa"; // FaDownload 추가
import "./MobileMenu.css"; // 별도의 스타일링 파일 생성

const MobileMenu = ({ onAddTransaction, onDownloadExcel }) => {
  const navigate = useNavigate();
  const handleRefresh = () => {
    // 새로고침 기능
    window.location.reload();
  };
  return (
    <div className="mobile-menu">
      <button
        className="menu-button"
        onClick={() => navigate("/")}
        aria-label="Home"
      >
        <FaHome />
      </button>
      <button
        className="menu-button"
        onClick={onAddTransaction}
        aria-label="Add Transaction"
      >
        <FaPlus />
      </button>
      <button
        className="menu-button"
        onClick={onDownloadExcel}
        aria-label="Download Excel"
      >
        <FaDownload /> {/* 다운로드 아이콘으로 변경 */}
      </button>
      <button
        className="menu-button"
        onClick={handleRefresh}
        aria-label="Refresh"
      >
        <FaSyncAlt />
      </button>
    </div>
  );
};

export default MobileMenu;
