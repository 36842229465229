// components/AvatarWithTooltip.js
import React from 'react';
import AvatarDisplay from './AvatarDisplay'

function AvatarWithTooltip({ avatarUrl, name }) {
  const defaultAvatar = 'https://api.dicebear.com/7.x/identicon/svg?seed=moose_amethyst';
  const src = avatarUrl || defaultAvatar;

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <AvatarDisplay
        avatarUrl={src}
        size={50}
      />
      <div
        style={{
          position: "absolute",
          top: "-15px",
          left: "60px",
          backgroundColor: "white",
          color: "black",
          padding: "5px 10px",
          borderRadius: "10px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          fontSize: "14px",
          whiteSpace: "normal",
          zIndex: 1000,
          wordWrap: "break-word",
          width: "210px",
        }}
      >
        Hello {name || "Guest"} Let's become rich.
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "-5px",
            width: "10px",
            height: "10px",
            backgroundColor: "white",
            transform: "rotate(45deg)",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>
    </div>
  );
}

export default AvatarWithTooltip;
