import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getAllPosts } from '../../services/blogService';
import { AuthContext } from '../../contexts/AuthContext';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthorInfo from "../../components/AuthorInfo";
const host = process.env.REACT_APP_API_HOST;

function BlogList() {
  const { user } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0);
  const limit = 20; // 한 페이지당 20개

  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    fetchPosts(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchPosts = async (page) => {
    try {
      const data = await getAllPosts(page, limit);
      if (data.success) {
        setPosts(data.posts);
        setTotalPosts(data.totalPosts);
      } else {
        toast.error(data.message || 'Failed to load list of articles.', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error has occurred.', toastOptions);
    }
  };

  const totalPages = Math.ceil(totalPosts / limit);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Bootstrap 페이지네이션 렌더링 함수
  const renderPagination = () => {
    let pages = [];
    for (let page = 1; page <= totalPages; page++) {
      pages.push(
        <li
          key={page}
          className={`page-item ${page === currentPage ? 'active' : ''}`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(page)}
          >
            {page}
          </button>
        </li>
      );
    }
    return (
      <ul className="pagination justify-content-center mt-4">
        {pages}
      </ul>
    );
  };

  return (
    <div className="container my-4">
      <div className="card bg-transparent text-light mb-3 p-3">
        <div className="row">
          <div className="col-md-6">
            <h3 className="card-title">Blog List</h3>
          </div>
          <div className="col-md-6">
            {user && (
              <Link to="/blog/create">
                <button className="btn btn-primary float-end">New Post</button>
              </Link>
            )}
          </div>
        </div>
      </div>

      {posts.map((post) => (
        <div className="card mb-3 bg-dark text-light" key={post._id}>
          <div className="card-body">
            {post.fileUrl ? (
              <div className="row">
                <div className="col-md-3 col-12">
                  <Link to={`/blog/${post._id}/${post.slug}`}>
                    <img
                      src={`${host}/${post.fileUrl}`}
                      alt={post.title}
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="col-md-9 col-12">
                  <h3 className="card-title">
                    <Link
                      to={`/blog/${post._id}/${post.slug}`}
                      className="text-decoration-none text-light"
                    >
                      {post.title}
                    </Link>
                  </h3>
                  <p>
                    {post.content.replace(/<[^>]+>/g, "").substring(0, 100)}...
                  </p>
                  <AuthorInfo 
                    userName={post.userName} 
                    userAvatar={post.userAvatar} 
                    createdAt={post.createdAt} 
                  />
                  {user && post.userId === user._id && (
                    <div className="mt-2">
                      <Link to={`/blog/edit/${post._id}`}>
                        <button className="btn btn-sm btn-outline-info">Edit</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <h3 className="card-title">
                  <Link
                    to={`/blog/${post._id}/${post.slug}`}
                    className="text-decoration-none text-light"
                  >
                    {post.title}
                  </Link>
                </h3>
                <p>
                  {post.content.replace(/<[^>]+>/g, "").substring(0, 100)}...
                </p>
                <AuthorInfo 
                  userName={post.userName} 
                  userAvatar={post.userAvatar} 
                  createdAt={post.createdAt} 
                />
                {user && post.userId === user._id && (
                  <div className="mt-2">
                    <Link to={`/blog/edit/${post._id}`}>
                      <button className="btn btn-sm btn-outline-info">Edit</button>
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}

      {renderPagination()}
      <ToastContainer />
    </div>
  );
}

export default BlogList;
