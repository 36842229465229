import React, { useEffect, useState } from "react";
import axios from "axios";
import { getSummary } from "../utils/ApiRequest";

const Summary = ({ userId, startDate, endDate }) => {
  const [summary, setSummary] = useState({
    totalIncome: 0,
    totalExpense: 0,
    balance: 0,
  });

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const { data } = await axios.post(getSummary, {
          userId: userId._id,
          startDate,
          endDate,
        });

        if (data.success) {
          setSummary({
            totalIncome: data.totalIncome,
            totalExpense: data.totalExpense,
            balance: data.balance,
          });
        }
      } catch (err) {
        console.error("Error fetching summary:", err);
      }
    };

    fetchSummary();
  }, [userId, startDate, endDate]);

  return (
    <div className="summary-cards">
      <div className="card income-card">
        <h3>Total Income</h3>
        <p>Rp. {summary.totalIncome.toLocaleString()}</p>
      </div>
      <div className="card expense-card">
        <h3>Total Expense</h3>
        <p>Rp. {summary.totalExpense.toLocaleString()}</p>
      </div>
      <div className="card balance-card">
        <h3>Balance</h3>
        <p>Rp. {summary.balance.toLocaleString()}</p>
      </div>
    </div>
  );
};

export default Summary;
