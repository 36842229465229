// src/pages/blog/BlogCreate.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { createPost } from '../../services/blogService';
import { AuthContext } from '../../contexts/AuthContext';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-quill/dist/quill.snow.css';

function BlogCreate() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(''); // 에디터에서 HTML 형태로 입력
  const [file, setFile] = useState(null);

  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      if (file) {
        formData.append('file', file);
      }
      // 로그인 사용자 정보
      formData.append('userId', user._id);
      formData.append('userName', user.name);
      // 도메인 (프로젝트 상황에 맞게)
      formData.append('domain', window.location.hostname); 
      formData.append('userAvatar', user.avatarImage || '');
      // or localStorage, or user info

      const data = await createPost(formData); // blogService.js
      if (data.success) {
        toast.success('The article was successfully registered.', toastOptions);
        navigate('/blog');
      } else {
        toast.error(data.message || 'Failed to save post', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error to save post.", toastOptions);
    }
  };

  return (
    <div className="container my-4">
      <div className="card bg-transparent mb-4 p-3">
        <h2 className="text-light">Create Blog</h2>
      </div>
      {/* 카드 형태로 폼을 감쌈 */}
      <div className="card bg-dark text-light">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {/* 제목 */}
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
                required
              />
            </div>

            {/* 내용(에디터) */}
            <div className="mb-3">
              <label className="form-label">Contents</label>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                style={{
                  backgroundColor: '#fff',
                  color: '#000'
                }}
              />
            </div>

            {/* 이미지 첨부 */}
            <div className="mb-3">
              <label className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
            <div className="d-flex justify-content-between">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <Link to="/blog" className="btn btn-secondary">
              List
            </Link>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BlogCreate;
