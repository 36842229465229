import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../contexts/AuthContext";
import { createComment } from "../services/commentService";

const CommentForm = ({ parentId, parentType, onCommentAdded }) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim()) return;
    setLoading(true);
    try {
      const response = await createComment({
        parentId,
        parentType,
        userId: user._id,
        userName: user.name,
        userAvatar: user.avatarImage || '',
        content,
      });
      if (response.success) {
        onCommentAdded(response.comment); // 부모 컴포넌트에 새 댓글 추가 알림
        setContent("");
      } else {
        alert(response.message);
      }
    } catch (err) {
      console.error("Error creating comment:", err.message);
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="commentContent">
        <ReactQuill
          theme="snow"
          value={content}
          onChange={setContent}
          placeholder="Write your comment here..."
          style={{
                  backgroundColor: '#fff',
                  color: '#000'
                }}
        />
      </Form.Group>
      <Button type="submit" disabled={loading} className="mt-2">
        {loading ? "Posting..." : "Post Comment"}
      </Button>
    </Form>
  );
};

export default CommentForm;
