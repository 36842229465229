import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Form, Button } from "react-bootstrap";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "./home.css";
import { deleteTransactions, editTransactions } from "../../utils/ApiRequest";
import axios from "axios";

const CardData = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [show, setShow] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [values, setValues] = useState({
    title: "",
    amount: "",
    description: "",
    category: "",
    date: "",
    transactionType: "",
  });

  // 수정 버튼 클릭 처리
  const handleEditClick = (itemKey) => {
    const editTran = transactions.find((item) => item._id === itemKey);
    if (editTran) {
      setEditingTransaction(editTran); // 선택된 거래 데이터 설정
      setValues({ ...editTran }); // 수정 폼에 최신 데이터 로드
      setShow(true); // 모달 표시
    }
  };

  // 삭제 버튼 클릭 처리
  const handleDeleteClick = async (itemKey) => {
    try {
      // 삭제 요청
      const { data } = await axios.delete(`${deleteTransactions}/${itemKey}`, {
        data: {
          userId: props.user._id, // 유저 ID 추가
        },
      });

      if (data.success) {
        // 삭제 성공 시 상태 업데이트
        setTransactions((prev) =>
          prev.filter((transaction) => transaction._id !== itemKey)
        );
        console.log("Transaction deleted successfully");
      } else {
        console.error("Delete failed:", data.message);
      }
    } catch (error) {
      console.error("Error deleting transaction:", error);
    }
  };

  // 수정 폼 제출 처리
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(`${editTransactions}/${editingTransaction._id}`, values);
      
      if (data.success) {
        setTransactions((prev) =>
          prev.map((transaction) =>
            transaction._id === editingTransaction._id ? { ...transaction, ...values } : transaction
          )
        );
  
        // 최신 데이터 반영
        setEditingTransaction((prev) => ({ ...prev, ...values }));
  
        setShow(false); // 모달 닫기
      }
    } catch (error) {
      console.error("Error updating transaction:", error);
    }
  };  

  // 입력 필드 값 변경 처리
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // 모달 닫기 처리
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setTransactions(props.data);
  }, [props.data]);

  return (
    <div className="card-container">
      {transactions.map((item, index) => (
        <div
          className="transaction-card"
          key={index}
          style={{
            borderLeft: `5px solid ${
              item.transactionType === "credit" ? "green" : "red"
            }`,
          }}
        >
          <div className="card-title">
            <h5>{item.title}</h5>
          </div>
          <div className="card-row">
            <span
              className="amount"
              style={{ color: item.transactionType === "credit" ? "green" : "red" }}
            >
              {item.transactionType === "credit"
                ? `+${item.amount.toLocaleString()}`
                : `-${item.amount.toLocaleString()}`}
            </span>
            <span className="date">{moment(item.date).format("YYYY-MM-DD")}</span>
          </div>
          <div className="card-row">
            <span className="category">{item.category}</span>
            <div className="actions">
              <EditNoteIcon
                sx={{ cursor: "pointer" }}
                onClick={() => handleEditClick(item._id)}
              />
              <DeleteForeverIcon
                sx={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDeleteClick(item._id)} // 삭제 핸들러 연결
              />
            </div>
          </div>
        </div>
      ))}

      {/* Modal for editing */}
      {editingTransaction && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditSubmit}>
              <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  name="amount"
                  type="number"
                  value={values.amount}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  name="category"
                  type="text"
                  value={values.category}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>description</Form.Label>
                <Form.Control
                  name="description"
                  type="text"
                  value={values.description}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  name="date"
                  type="date"
                  value={moment(values.date).format("YYYY-MM-DD")}
                  onChange={handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default CardData;
