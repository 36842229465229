import React from "react";
import AvatarDisplay from "./AvatarDisplay"; // 아바타 표시 컴포넌트 (예: 위에서 만든 간단 아바타 컴포넌트)

const AuthorInfo = ({ userName, userAvatar, createdAt }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <AvatarDisplay avatarUrl={userAvatar} size={30} />
        <span style={{ marginLeft: "10px", marginRight: "10px", fontWeight: "bold" }}>
          {userName}
        </span>
      </div>
      <div style={{ fontSize: "0.9rem", color: "#888" }}>
        {new Date(createdAt).toLocaleString()}
      </div>
    </div>
  );
};

export default AuthorInfo;
