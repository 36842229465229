// src/services/blogService.js
import axios from 'axios';
import { blogBaseAPI } from "../utils/ApiRequest";

export const getAllPosts = async (page = 1, limit = 30) => {
    const res = await axios.get(`${blogBaseAPI}?page=${page}&limit=${limit}`);
    return res.data;  // { success, posts, totalPosts }
  };

export const getPostById = async (id, slug) => {
  const res = await axios.get(`${blogBaseAPI}/${id}/${slug}`);
  return res.data;  // { success: true, post: {...} }
};

export const createPost = async (formData) => {
  const res = await axios.post(blogBaseAPI, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return res.data;
};

export const updatePost = async (id, formData) => {
  const res = await axios.put(`${blogBaseAPI}/${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return res.data;
};

export const deletePost = async (id, userId) => {
  console.log(`${blogBaseAPI}/${id}`);
  const res = await axios.delete(`${blogBaseAPI}/${id}`, {
    data: { userId }
  });
  return res.data;
};
