import axios from "axios";
import { commentBaseAPI } from "../utils/ApiRequest"; // commentBaseAPI 예: '/api/v1/comments'

export const createComment = async (commentData) => {
  const res = await axios.post(commentBaseAPI, commentData);
  return res.data;
};

export const updateComment = async (commentId, commentData) => {
  const res = await axios.put(`${commentBaseAPI}/${commentId}`, commentData);
  return res.data;
};

export const deleteComment = async (commentId, userId) => {
  const res = await axios.delete(`${commentBaseAPI}/${commentId}`, {
    data: { userId },
  });
  return res.data;
};

export const getCommentsForParent = async (parentType, parentId, page = 1, limit = 10) => {
    const res = await axios.get(
      `${commentBaseAPI}/${parentType}/${parentId}?page=${page}&limit=${limit}`
    );
    return res.data;
  };
