// components/AvatarDisplay.js
import React from 'react';

function AvatarDisplay({ avatarUrl, size = 50 }) {
  const defaultAvatar = 'https://api.dicebear.com/7.x/identicon/svg?seed=moose_amethyst';
  const src = avatarUrl || defaultAvatar;

  return (
    <img
      src={src}
      alt="User Avatar"
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        border: '2px solid white',
        objectFit: 'cover',
      }}
    />
  );
}

export default AvatarDisplay;
