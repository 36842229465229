// src/components/MainLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

function MainLayout() {
  return (
    <div style={{ background: "black", color: "white", minHeight: "100vh" }}>
      {/* 상단 공통 헤더 */}
      <Header />
      
      {/* 메인 컨텐츠 자리 (하위 라우트가 Outlet을 통해 렌더링됨) */}
      <div style={{ padding: "20px" }}>
        <Outlet />
      </div>

      {/* 하단에 공통으로 깔리는 MobileMenu 등을 놓고 싶다면 */}
    </div>
  );
}

export default MainLayout;
