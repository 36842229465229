// src/pages/blog/BlogEdit.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getPostById, updatePost } from '../../services/blogService';
import { AuthContext } from '../../contexts/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BlogEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [file, setFile] = useState(null);
  const [userId, setUserId] = useState('');

  const toastOptions = {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, []);

  const fetchPost = async () => {
    try {
      const data = await getPostById(id);
      if (data.success) {
        setTitle(data.post.title);
        setContent(data.post.content);
        setUserId(user._id);
      } else {
        toast.error(data.message || 'The article could not be loaded.', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error has occurred.', toastOptions);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('userId', userId);
      if (file) {
        formData.append('file', file);
      }

      const data = await updatePost(id, formData);
      if (data.success) {
        toast.success('The article has been successfully edited.', toastOptions);
        navigate('/blog');
      } else {
        toast.error(data.message || 'Modification failed', toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error('An error has occurred.', toastOptions);
    }
  };

  return (
    <div className="container my-4">
      <div className="card bg-transparent mb-4 p-3">
        <h2 className="text-light">Blog Edit</h2>
      </div>
      <div className="card bg-dark text-light">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            {/* 제목 */}
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input 
                type="text" 
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="enter title"
                required
              />
            </div>

            {/* 내용 */}
            <div className="mb-3">
              <label className="form-label">Comtents</label>
              <ReactQuill 
                theme="snow"
                value={content}
                onChange={setContent}
                style={{
                  height: '200px',
                  backgroundColor: '#fff',
                  color: '#000'
                }}
              />
            </div>

            {/* 이미지 변경 */}
            <div className="mb-3">
              <label className="form-label">Image</label>
              <input 
                type="file" 
                className="form-control"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>

            {/* 버튼 그룹: 목록과 수정하기 */}
            <div className="d-flex justify-content-between">
              <Link to="/blog">
                <button type="button" className="btn btn-secondary">List</button>
              </Link>
              <button type="submit" className="btn btn-primary">Send</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BlogEdit;
