// const host = "https://expense-tracker-app-knl1.onrender.com";
const host = process.env.REACT_APP_API_HOST;
export const setAvatarAPI = `${host}/api/auth/setAvatar`;
export const registerAPI = `${host}/api/auth/register`;
export const loginAPI = `${host}/api/auth/login`;
export const addTransaction = `${host}/api/v1/transactions/add`;
export const getTransactions = `${host}/api/v1/transactions/list`;
export const editTransactions = `${host}/api/v1/transactions`;
export const deleteTransactions = `${host}/api/v1/transactions`;
export const getSummary = `${host}/api/v1/transactions/summary`;
export const exportTransactionsAPI = `${host}/api/v1/transactions/exportTransactions`;

export const getCategoriesAPI = `${host}/api/v1/categories`;
export const addCategoryAPI = `${host}/api/v1/categories`;
export const updateCategoryAPI = `${host}/api/v1/categories`;
export const deleteCategoryAPI = `${host}/api/v1/categories`;
export const updateCategoryOrderAPI = `${host}/api/v1/categories/order`;
export const blogBaseAPI = `${host}/api/v1/blog`;
export const commentBaseAPI = `${host}/api/v1/comment`;