import React, { useState, useEffect, useContext } from "react";
import CommentForm from "../../components/CommentForm";
import CommentList from "../../components/CommentList";
import { getCommentsForParent } from "../../services/commentService";
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Pagination } from "react-bootstrap";

const BlogComments = ({ blogPostId }) => {
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [totalComments, setTotalComments] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 10; // 한 페이지당 10개 댓글

  const fetchComments = async () => {
    try {
      const response = await getCommentsForParent("blog", blogPostId, page, limit);
      if (response.success) {
        setComments(response.comments);
        setTotalComments(response.totalComments);
      }
    } catch (err) {
      console.error("Error fetching comments:", err.message);
    }
  };

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line
  }, [blogPostId, page]);

  const handleCommentAdded = (newComment) => {
    // 새 댓글이 추가되면 페이지를 1로 초기화하여 새로 불러오거나, 기존 배열에 추가할 수 있습니다.
    setComments((prev) => [newComment, ...prev]);
    setTotalComments((prev) => prev + 1);
  };

  const handleCommentUpdated = (updatedComment) => {
    setComments((prev) =>
      prev.map((comment) =>
        comment._id === updatedComment._id ? updatedComment : comment
      )
    );
  };

  const handleCommentDeleted = (deletedCommentId) => {
    setComments((prev) =>
      prev.filter((comment) => comment._id !== deletedCommentId)
    );
    setTotalComments((prev) => prev - 1);
  };

  // 간단한 페이지네이션 UI
  const totalPages = Math.ceil(totalComments / limit);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="mt-3">
      <h3>Comments</h3>
      {user && (
      <CommentForm 
        parentId={blogPostId} 
        parentType="blog" 
        onCommentAdded={handleCommentAdded} 
      />
      )}
      <CommentList
        comments={comments}
        onCommentUpdated={handleCommentUpdated}
        onCommentDeleted={handleCommentDeleted}
      />
      {totalPages > 1 && (
        <Pagination className="mt-3 justify-content-center">
          <Pagination.Prev onClick={() => handlePageChange(page > 1 ? page - 1 : 1)} disabled={page === 1} />
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === page}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(page < totalPages ? page + 1 : totalPages)} disabled={page === totalPages} />
        </Pagination>
      )}
    </div>
  );
};

export default BlogComments;
