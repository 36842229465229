import React, { useState, useContext } from "react";
import { ListGroup, Button } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { updateComment, deleteComment } from "../services/commentService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaEdit, FaTrash, FaSave, FaTimes } from "react-icons/fa";
import AuthorInfo from "./AuthorInfo";

const CommentList = ({ comments, onCommentUpdated, onCommentDeleted }) => {
  const { user } = useContext(AuthContext);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editContent, setEditContent] = useState("");

  const handleEdit = (comment) => {
    setEditingCommentId(comment._id);
    setEditContent(comment.content);
  };

  const handleUpdate = async (commentId) => {
    if (!editContent.trim()) return;
    try {
      const response = await updateComment(commentId, {
        userId: user._id,
        content: editContent,
      });
      if (response.success) {
        onCommentUpdated(response.comment);
        setEditingCommentId(null);
      } else {
        alert(response.message);
      }
    } catch (err) {
      console.error("Error updating comment:", err.message);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      const response = await deleteComment(commentId, user._id);
      if (response.success) {
        onCommentDeleted(commentId);
      } else {
        alert(response.message);
      }
    } catch (err) {
      console.error("Error deleting comment:", err.message);
    }
  };

  return (
    <ListGroup variant="flush" className="mt-4">
      {comments.map((comment) => (
        <ListGroup.Item key={comment._id} className="mt-2">
          <div style={{ display: "flex", alignItems: "center" }}>
          <AuthorInfo 
                userName={comment.userName} 
                userAvatar={comment.userAvatar} 
                createdAt={comment.createdAt} 
            />
          </div>
          {editingCommentId === comment._id ? (
            <>
              <ReactQuill
                theme="snow"
                value={editContent}
                onChange={setEditContent}
              />
              <div className="mt-2">
                <Button variant="success" size="sm" onClick={() => handleUpdate(comment._id)}>
                  <FaSave />
                </Button>
                <Button variant="secondary" size="sm" onClick={() => setEditingCommentId(null)} className="ms-2">
                  <FaTimes />
                </Button>
              </div>
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: comment.content }} />
              {user && comment.userId.toString() === user._id.toString() && (
                <div className="mt-2">
                  <Button variant="outline-primary" size="sm" onClick={() => handleEdit(comment)}>
                    <FaEdit />
                  </Button>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDelete(comment._id)} className="ms-2">
                    <FaTrash />
                  </Button>
                </div>
              )}
            </>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default CommentList;
