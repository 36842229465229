import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import { FaEdit, FaTrash, FaGripVertical } from "react-icons/fa"; // 아이콘 임포트
import { CategoryContext } from "../contexts/CategoryContext";

const CategoryManager = ({ show, handleClose }) => {
  const {
    categories,
    createCategory,
    updateCategory,
    removeCategory,
    updateCategoryOrder,
    fetchCategories,
  } = useContext(CategoryContext);

  const [newCategory, setNewCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      fetchCategories();
    }
    // eslint-disable-next-line
  }, [show]);

  const handleInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    setNewCategory(category.name);
  };

  const handleAddOrUpdate = async () => {
    if (!newCategory.trim()) return;
    setLoading(true);
    if (editingCategory) {
      await updateCategory(editingCategory._id, newCategory);
      setEditingCategory(null);
    } else {
      await createCategory(newCategory);
    }
    setNewCategory("");
    setLoading(false);
  };

  const handleDeleteClick = async (id) => {
    setLoading(true);
    await removeCategory(id);
    setLoading(false);
  };

  // 드래그 종료 시 순서 업데이트
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newCategories = Array.from(categories);
    const [removed] = newCategories.splice(result.source.index, 1);
    newCategories.splice(result.destination.index, 0, removed);
    updateCategoryOrder(newCategories);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Manage Categories</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="categoryInput">
            <Form.Label>
              {editingCategory ? "Edit Category" : "Add New Category"}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter category name"
              value={newCategory}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Button className="mt-3" onClick={handleAddOrUpdate} disabled={loading}>
            {editingCategory ? "Update" : "Add"}
          </Button>
        </Form>
        <hr />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="categories">
            {(provided) => (
              <ListGroup ref={provided.innerRef} {...provided.droppableProps}>
                {categories.map((category, index) => (
                  <Draggable
                    key={category._id}
                    draggableId={category._id}
                    index={index}
                  >
                    {(provided) => (
                      <ListGroup.Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {/* 드래그 핸들 아이콘 (여기에 dragHandleProps) */}
                        <span
                          {...provided.dragHandleProps}
                          style={{ cursor: "grab", marginRight: "8px" }}
                        >
                          <FaGripVertical />
                        </span>
                        {/* 카테고리 이름 */}
                        <span>{category.name}</span>
                        <div>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEditClick(category)}
                          >
                            <FaEdit />
                          </Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteClick(category._id)}
                          >
                            <FaTrash />
                          </Button>
                        </div>
                      </ListGroup.Item>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ListGroup>
            )}
          </Droppable>
        </DragDropContext>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryManager;
