// NavbarComponent.js
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import CategoryManager from "./CategoryManager";
import { AuthContext } from '../contexts/AuthContext';
import Avatar from "./avatar";

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleShowLogin = () =>{
    navigate("/login");
  }

  const handleSetAvatar = () =>{
    navigate("/setAvatar");
  }

  const handleBlog = () =>{
    navigate("/blog");
  }

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const handleShowLogout = () => {
    logout();
    navigate("/login");
  }

  const handleHome = () => {
    navigate("/");
  }
  const particlesInit = useCallback(async (engine) => {
    // console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);
  
  return (
    <>
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: '#000',
            },
          },
          fpsLimit: 60,
          particles: {
            number: {
              value: 200,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: '#ffcc00',
            },
            shape: {
              type: 'circle',
            },
            opacity: {
              value: 0.5,
              random: true,
            },
            size: {
              value: 3,
              random: { enable: true, minimumValue: 1 },
            },
            links: {
              enable: false,
            },
            move: {
              enable: true,
              speed: 2,
            },
            life: {
              duration: {
                sync: false,
                value: 3,
              },
              count: 0,
              delay: {
                random: {
                  enable: true,
                  minimumValue: 0.5,
                },
                value: 1,
              },
            },
          },
          detectRetina: true,
        }}
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    <Navbar className="navbarCSS" collapseOnSelect expand="lg" style={{position: 'relative', zIndex: "2 !important"}}>
      {/* <Navbar className="navbarCSS" collapseOnSelect expand="lg" bg="dark" variant="dark"> */}
        <Navbar.Brand href="/" className="text-white navTitle">
          <Avatar 
            avatarUrl={user?.avatarImage || ''}
            name={user?.name || ''}
          />
        </Navbar.Brand>
        <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
          >
            <span
              className="navbar-toggler-icon"
              style={{
                background: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`,
              }}
            ></span>
          </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse custom-collapse" style={{color: "white"}}>
          {user ? (
            <>
              <Nav>
                <Button variant="primary" onClick={handleHome} className="ml-2 m-1">Home</Button>
                <Button variant="primary" onClick={() => setShowCategoryModal(true)}  className="ml-2 m-1">Categories</Button>
                <CategoryManager
                  user={user}
                  show={showCategoryModal}
                  handleClose={() => setShowCategoryModal(false)}
                />
                <Button variant="light" onClick={handleSetAvatar} className="ml-2 m-1">Set Avatar</Button>
                <Button variant="light" onClick={handleBlog} className="ml-2 m-1">Blog</Button>
                <Button variant="primary" onClick={handleShowLogout} className="ml-2 m-1">Logout</Button>
              </Nav>
            </>
          ) : (

            <>
              <Nav>
                <Button variant="light" onClick={handleBlog} className="ml-2 m-1">Blog</Button>
                <Button variant="primary" onClick={handleShowLogin} className="ml-2">Login</Button>
              </Nav>
            </>
          )}
          
        </Navbar.Collapse>
      </Navbar>
      </div>
    </>
  );
};

export default Header;
